import React, { FC } from "react";

import { theme } from "flicket-ui";
import { Status } from "~components";
import { getRandomColor } from "~lib";
import { FinancialGatewayGraphOutput } from "~graphql/sdk";
import { Card } from "../";
import { PieChart } from "../charts";

export const FinancialGateways: FC<{
  data: FinancialGatewayGraphOutput;
  error: any;
}> = ({ data, error }) => {
  const COLORS = [theme.colors.P300, theme.colors.S300, "#D3CC2C"];

  return (
    <Card title="Gateways">
      <Status loading={!error && !data} error={error}>
        <PieChart
          data={data?.integrations?.map((item, index) => ({
            label: item.name,
            value: item.transactions,
            color: COLORS[index] || getRandomColor(),
          }))}
          label={`Transaction${data?.transactions === 1 ? "" : "s"}`}
          total={data?.transactions?.toLocaleString()}
        />
      </Status>
    </Card>
  );
};
