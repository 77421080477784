import { addDays, subDays } from "date-fns";
import {
  OrderChannel,
  PointReportingFilterSource,
  ReportingFilterSource,
  ReportingFinancialSalesBreakdownSource,
} from "~graphql/sdk";

export const OPTION_ALL = "all";

export const CHANNEL_OPTIONS = [
  { label: "All channels", value: OPTION_ALL },
  { label: "Online", value: OrderChannel.Online },
  { label: "Back office", value: OrderChannel.BackOffice },
  { label: "POS", value: OrderChannel.Pos },
];

export const SOURCE_LIST = {
  ...ReportingFilterSource,
  ...ReportingFinancialSalesBreakdownSource,
  ...PointReportingFilterSource,
};

export const today = new Date();
today.setHours(0, 0, 0, 0);

export const DEFAULT_DATES = {
  startDate: subDays(today, 31),
  endDate: addDays(today, 1),
};
