import { FC } from "react";
import {
  Flex,
  system,
  Box,
  Text,
  SecondaryButton,
  TertiaryButton,
  Price,
} from "flicket-ui";
import styled, { css } from "styled-components";
import { Icon } from "~components/common/Icon";
import { formatDate, getImage } from "~lib";
import { DashboardEventsQuery } from "~graphql/sdk";
import useScreenSize from "~hooks/useScreenSize";

const StyledContainer = styled(Box)`
  background-color: #fafafa;
  width: 100%;
  border-radius: 6px;
  box-shadow: ${(p) => p.theme.shadows.button};

  ${system};
`;

const Grid = styled(Box)`
  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    height: 100%;
  }

  ${system}
`;

const imageCss = (image) => css`
  width: 100%;
  height: 120px;
  border-radius: 6px 6px 0 0;
  background-repeat: no-repeat;
  background-position: ${image ? "top-left" : "center"};
  background-size: cover;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    height: 100%;
    border-radius: 6px;
  }
`;

interface EventSummaryCardProps {
  event: DashboardEventsQuery["events"]["edges"][number]["node"];
}

export const getViewModel = (event: EventSummaryCardProps["event"]) => {
  return {
    title: event.title,
    key: event.id,
    date: formatDate(event.startDate, `EEE, MMM d 'at' HH:mm a`),
    location: `${event?.venue?.name}, ${event?.venue?.address?.city}`,
    image: event.logo,
    editLink: `events/${event.id}`,
    reportLink: `reports/sales?source=EVENT&sourceId=${event.id}&referrer=/`,
    sales: {
      today: event.soldTicketsCountToday,
      yesterday: event.soldTicketsCountYesterday,
      total: event.soldTicketsCount,
      revenue: event.totalRevenue,
    },
  };
};

export const EventSummaryCard: FC<EventSummaryCardProps> = ({ event }) => {
  const isMobile = useScreenSize().isTabletPortraitDown;

  const {
    title,
    date,
    location,
    image,
    reportLink,
    editLink,
    sales: { today = 0, yesterday = 0, total = 0, revenue = 0 } = {},
  } = getViewModel(event);

  return (
    <StyledContainer mb={3}>
      <Grid>
        <Box {...(isMobile ? { p: 0, pb: 1 } : { p: 2, pr: 1 })}>
          <Box
            css={imageCss(image)}
            backgroundImage={`url(${getImage(
              image,
              "/static/no-image-landscape.png"
            )})`}
          />
        </Box>
        <Box
          {...(isMobile ? { p: 1 } : { p: 2, pl: 1, pb: 2 })}
          position="relative"
        >
          <Text
            ellipsis
            fontSize={isMobile ? 3 : 6}
            fontWeight={"demiBold"}
            style={{
              position: isMobile ? "static" : "absolute",
              width: "96%",
            }}
          >
            {title}
          </Text>

          <Grid
            css={{
              paddingTop: isMobile ? 12 : 44,
            }}
          >
            <Flex
              flexDirection={"column"}
              justifyContent={"space-between"}
              borderRightColor={isMobile ? "transparent" : "N200"}
              pr={2}
              pt={1}
              style={{
                borderRightWidth: 1,
                borderRightStyle: "solid",
              }}
            >
              <Box flexGrow={1}>
                <Box mb={1}>
                  <Flex alignItems="flex-start" justifyContent="left">
                    <Icon
                      icon="date-range"
                      fontSize={isMobile ? 4 : 6}
                      color="N800"
                    />
                    <Text
                      fontWeight="medium"
                      fontSize={isMobile ? 2 : 3}
                      ml={1}
                    >
                      {date}
                    </Text>
                  </Flex>
                </Box>
                <Box>
                  <Flex alignItems="flex-start" justifyContent="left">
                    <Icon icon="map" fontSize={isMobile ? 4 : 6} color="N800" />
                    <Text
                      fontWeight="medium"
                      fontSize={isMobile ? 2 : 3}
                      ml={1}
                    >
                      {location}
                    </Text>
                  </Flex>
                </Box>
              </Box>

              {!isMobile && (
                <Flex
                  flexGrow={1}
                  alignItems="end"
                  justifyContent={"space-between"}
                >
                  <TertiaryButton href={editLink} mr={1} py={1} px={3}>
                    Edit event
                  </TertiaryButton>
                  <SecondaryButton href={reportLink} py={1} px={3}>
                    View report
                  </SecondaryButton>
                </Flex>
              )}
            </Flex>
            <Flex
              pt="1/2"
              flexDirection={"column"}
              justifyContent={"space-between"}
              css={{
                paddingLeft: isMobile ? 0 : 16,
                marginTop: isMobile ? 12 : 0,
                minHeight: isMobile ? 140 : "auto",
              }}
            >
              <Box flexGrow={1}>
                <Text variant="heavy.M" style={{ textTransform: "uppercase" }}>
                  Ticket Sales
                </Text>
              </Box>
              <Box flexGrow={1}>
                <Flex justifyContent="space-between" lineHeight={"medium"}>
                  <Box>
                    <Text fontSize={2}>Today</Text>
                    <Text variant={isMobile ? "extraBold.L" : "extraBold.XL"}>
                      {today}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize={2}>Yesterday</Text>
                    <Text variant={isMobile ? "extraBold.L" : "extraBold.XL"}>
                      {yesterday}
                    </Text>
                  </Box>
                  <Box>
                    <Text fontSize={2}>Total</Text>
                    <Text variant={isMobile ? "extraBold.L" : "extraBold.XL"}>
                      {total}
                    </Text>
                  </Box>
                </Flex>
              </Box>
              <Box flexGrow={0}>
                <Box>
                  <Flex justifyContent="space-between" alignItems={"baseline"}>
                    <Flex>
                      <Text fontWeight="medium" fontSize={isMobile ? 2 : 3}>
                        Total Revenue
                      </Text>
                    </Flex>
                    <Flex>
                      <Text variant={isMobile ? "extraBold.L" : "extraBold.XL"}>
                        <Price price={revenue} />
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              </Box>
            </Flex>

            {isMobile && (
              <>
                <Box>
                  <TertiaryButton
                    href={editLink}
                    fontSize={3}
                    mt={2}
                    mb={1}
                    w="100%"
                  >
                    Edit event
                  </TertiaryButton>
                </Box>
                <Box>
                  <SecondaryButton
                    href={reportLink}
                    fontSize={3}
                    mt={2}
                    mb={1}
                    w="100%"
                  >
                    View report
                  </SecondaryButton>
                </Box>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
    </StyledContainer>
  );
};

export default EventSummaryCard;
