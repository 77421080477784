import React, { FC } from "react";

import { theme, formatPrice } from "flicket-ui";
import { formatDate } from "~lib/helpers";

import { BarChart } from "../charts";

export const FinancialsChart: FC<{ data: any }> = ({ data }) => {
  const options = {
    y: {
      tickFormatter: (val) => `$${val.toFixed(2).toLocaleString()}`,
    },
    x: {
      tickFormatter: (val) => {
        const date = new Date(val);
        date.setHours(0, 0, 0, 0);
        return formatDate(date, "d MMM yyyy");
      },
    },
    tooltip: {
      formatter: (val) => formatPrice(val),
    },
  };

  const bars = [
    { name: "Gross sales", dataKey: "grossSales", fill: theme.colors.S300 },
    { name: "Service fees", dataKey: "serviceFees", fill: theme.colors.S200 },
    {
      name: "Transaction costs",
      dataKey: "transactionCosts",
      fill: theme.colors.P300,
    },
    {
      name: "Delivery costs",
      dataKey: "deliveryCosts",
      fill: theme.colors.P200,
    },
  ];

  const formatGraphData = (dates) =>
    dates?.map(({ date, items }) => {
      const grossSales =
        items.find((item) => item.name === "Gross sales")?.value || 0;
      const serviceFees =
        items.find((item) => item.name === "Service fees")?.value || 0;
      const transactionCosts =
        items.find((item) => item.name === "Transaction costs")?.value || 0;
      const deliveryCosts =
        items.find((item) => item.name === "Delivery costs")?.value || 0;

      return { date, grossSales, serviceFees, transactionCosts, deliveryCosts };
    });

  return (
    <BarChart
      data={formatGraphData(data?.dates)}
      options={options}
      items={bars}
    />
  );
};
