import { capitalize, startCase } from "lodash";
import { useRouter } from "next/router";
import { useState } from "react";
import { Grid, Status } from "~components";
import { NAV_ITEMS } from "~components/common/Navbar";
import { ParamsProps } from "~features/useParams/hooks/useParams";
import { GraphFilterType, PointReportingFilterSource } from "~graphql/sdk";
import {
  CustomerLocationsDocument,
  SalesChannelGraphDocument,
  SalesGraphDocument,
  SalesOverviewDocument,
} from "~graphql/typed-document-nodes";
import { useOrganization, usePermissions, useSDK, useUser } from "~hooks";
import { useQuery } from "~hooks/useQuery";
import { Card } from "../Card";
import { CustomerLocations } from "../charts/CustomerLocations";
import { formatReportingParams } from "../util";
import { SalesChannelChart } from "./SalesChannelChart";
import { SalesChart } from "./SalesChart";
import { SalesOverview } from "./SalesOverview";

type SalesProps = {
  params: ParamsProps;
};

export const Sales = ({ params }: SalesProps) => {
  const [type, setType] = useState<GraphFilterType>(GraphFilterType.Overall);
  const { hasPermissions, Permission } = usePermissions();
  const { user } = useUser();
  const router = useRouter();
  const { hasFeature } = useOrganization();

  const formattedParams = formatReportingParams<
    ParamsProps,
    PointReportingFilterSource
  >(params);

  const { data: graphData, error: errorGraph } = useQuery(
    params.ready ? SalesGraphDocument : null,
    { ...formattedParams, type }
  );

  const { data: salesOverview, error: errorOverview } = useQuery(
    params.ready ? SalesOverviewDocument : null,
    formattedParams
  );

  const { data: customerLocations, error: errorCustomerLocations } = useQuery(
    params.ready ? CustomerLocationsDocument : null,
    formattedParams
  );

  const { data: salesChannelGraph, error: errorSalesChannelGraph } = useQuery(
    params.ready ? SalesChannelGraphDocument : null,
    formattedParams
  );

  const allOptions = Object.values(GraphFilterType).map((val) => ({
    label: capitalize(startCase(val)),
    onClick: () => setType(val),
  }));

  let options: {
    label: string;
    onClick: () => void;
  }[];

  if (params.source === PointReportingFilterSource.Package) {
    options = allOptions.filter((option) => option.label === "Overall");
  } else {
    options = allOptions;
  }

  const dropdownOptions = {
    options,
    title: capitalize(startCase(type)),
  };

  if (!hasPermissions(Permission.ReportingSales)) {
    const firstRoute = NAV_ITEMS({ hasPermissions, user, hasFeature })[0];
    void router.replace(firstRoute.url);
    return null;
  }

  return (
    <>
      <SalesOverview
        data={salesOverview?.salesOverview}
        error={errorOverview}
        mb={4}
        mt={4}
      />

      <Card title="Sales" mb={4} dropdown={dropdownOptions}>
        <Status
          loading={!errorGraph && !graphData?.salesGraph}
          error={errorGraph}
        >
          <SalesChart data={graphData?.salesGraph} error={errorGraph} />
        </Status>
      </Card>

      <Grid
        width={1}
        gridTemplateColumns={{
          _: "repeat(auto-fill, minmax(300px, 1fr))",
          sm: "repeat(auto-fill, minmax(400px, 1fr))",
        }}
      >
        <CustomerLocations
          data={customerLocations?.customerLocations}
          error={errorCustomerLocations}
          variant="sales"
        />
        <SalesChannelChart
          data={salesChannelGraph?.salesChannelGraph}
          error={errorSalesChannelGraph}
        />
      </Grid>
    </>
  );
};

export default Sales;
