import React, { FC } from "react";

import { theme } from "flicket-ui";

import { formatDate } from "~lib";
import { BarChart } from "../charts";
import { addMinutes } from "date-fns";
import { useIsMobile } from "~hooks";
import { ScanVisitorsOverTimeQuery } from "~graphql/sdk";
import { GetGraphDataReturnType } from "~pages/pos/reporting";

const getDateFormat = (val: number) =>
  `${formatDate(new Date(val), "d LLL p")} - ${formatDate(
    addMinutes(new Date(val), 5),
    "p"
  )}`;

const formatGraphData = (
  data:
    | ScanVisitorsOverTimeQuery["scanVisitorsOverTime"]
    | GetGraphDataReturnType,
  timeBased?: boolean,
  percentageBased?: boolean
) => {
  if (timeBased) {
    return (data as ScanVisitorsOverTimeQuery["scanVisitorsOverTime"])?.map(
      ({ start, value }) => {
        return {
          date: new Date(start).getTime(),
          scanned: value,
        };
      }
    );
  }

  if (percentageBased) {
    return (data as GetGraphDataReturnType)?.map(
      ({ name, issued, scanned }) => {
        const scannedPercentage = Math.round((scanned / issued) * 100);
        const toScanPercentage = 100 - scannedPercentage;

        return {
          date: name,
          scanned: scannedPercentage,
          expected: toScanPercentage,
          total: issued,
        };
      }
    );
  }
};

export const ScansChart: FC<{
  data:
    | ScanVisitorsOverTimeQuery["scanVisitorsOverTime"]
    | GetGraphDataReturnType;
  timeBased?: boolean;
  percentageBased?: boolean;
}> = ({ data, timeBased = false, percentageBased = false }) => {
  const isMobile = useIsMobile();

  const options = {
    y: {
      tickFormatter: (val) =>
        percentageBased ? `${val || 0}%` : val.toLocaleString(),
      labelFormatter: (val) => val.toLocaleString(),
      ...(percentageBased && { domain: [0, 100] }),
    },
    x: {
      tickFormatter: (val: string | number) =>
        timeBased ? formatDate(new Date(val), "d LLL p") : val,
      labelFormatter: (val: string) => val,
      ...(timeBased && {
        hide: false,
        interval: "preserveStartEnd",
        angle: 0,
        type: "number",
        scale: "time",
        minTickGap: isMobile ? 5 : 50,
        domain: ["dataMin", "dataMax"],
      }),
    },
    tooltip: {
      formatter: (val, name, props) =>
        percentageBased
          ? name === "Scanned"
            ? `${val || 0}%`
            : props?.payload?.total
          : val,
      labelFormatter: (val: string | number) =>
        timeBased ? getDateFormat(val as number) : val,
    },
  };

  const bars = [
    {
      name: "Scanned",
      dataKey: "scanned",
      fill: theme.colors.S300,
    },
    ...(timeBased
      ? []
      : [
          {
            name: percentageBased ? "Expected" : "Issued",
            dataKey: "expected",
            fill: theme.colors.S200,
          },
        ]),
  ].map((item) => (percentageBased ? { ...item, stackId: "a" } : item));

  return (
    <BarChart
      data={formatGraphData(data, timeBased, percentageBased)}
      options={options}
      items={bars}
      hideLegend={timeBased || percentageBased}
    />
  );
};
