import { FC } from "react";
import { SystemProps } from "flicket-ui";
import { Status } from "~components";
import { SalesOverviewQuery } from "~graphql/sdk";
import { Card, OmitTypename } from "../";
import { IconCardGrid, OverviewItem } from "../IconCardGrid";

type SalesOverviewData = OmitTypename<SalesOverviewQuery["salesOverview"]>;

interface Props {
  data: SalesOverviewData;
  error: any;
}

const overviewItems: OverviewItem<SalesOverviewData>[] = [
  { title: "Tickets issued", icon: "my-tickets", key: "ticketsSold" },
  { title: "Total tickets sold", icon: "my-tickets", key: "ticketsPaid" },
  { title: "Total comps issued", icon: "my-tickets", key: "ticketsComp" },
  {
    title: "Ticket protection tickets",
    icon: "add-cart",
    key: "ticketCoverTicketsSold",
  },
  {
    title: "Ticket protection memberships",
    icon: "add-cart",
    key: "ticketCoverMembershipsSold",
  },
  { title: "Memberships issued", icon: "membership", key: "membershipsSold" },
  {
    title: "Memberships renewed",
    icon: "membership",
    key: "membershipsRenewed",
  },
  {
    title: "Tickets resold",
    icon: "membership",
    key: "ticketsTransferred",
  },
  { title: "Add-ons issued", icon: "add-cart", key: "addonsSold" },
  { title: "Orders", icon: "orders", key: "orders" },
  {
    title: "Ticket protection orders",
    icon: "add-cart",
    key: "ticketCoverOrdersSold",
  },
  { title: "Cart abandonment", icon: "cart", key: "cartAbandonment" },
  { title: "Cart recovered", icon: "cart", key: "cartRecovered" },
  {
    title: "Returning customers",
    icon: "customer",
    key: "returningCustomers",
  },
  { title: "Packages sold", icon: "redeem", key: "packagesSold" },
  {
    title: "Points distributed",
    icon: "redeem",
    key: "pointsDistributed",
    description: "Number of points sold via packages.",
  },
];

export const SalesOverview: FC<Props & SystemProps> = ({
  data,
  error,
  ...props
}) => (
  <>
    <Card
      title="Sales overview"
      pr={{ _: 0, md: 4 }}
      position="relative"
      {...props}
    >
      <Status loading={!error && !data} error={error}>
        <IconCardGrid
          variant="sales"
          items={overviewItems}
          data={data}
          getDataValue={(data, key) => data[key].toLocaleString()}
          hideZeroValues={true}
        />
      </Status>
    </Card>
  </>
);

export default SalesOverview;
