import { Box, Container, Flex, SystemProps, Text } from "flicket-ui";
import { Icon } from "~components";
import { IconName } from "~components/common/Icon/Icon";
import { Grid } from "./Grid";
import { useIsMobile } from "~hooks";
import ReactTooltip from "react-tooltip";

export interface OverviewItem<TData extends { [key: string]: any }> {
  title: string;
  icon: IconName;
  key: keyof TData;
  description?: string;
}

const variants: { [key: string]: SystemProps["backgroundColor"] } = {
  sales: "P300",
  financial: "S300",
  insights: "P300",
};

type IconCardVariant = keyof typeof variants;

interface IconCardGridProps<TData> {
  data: TData;
  items: OverviewItem<TData>[];
  getDataValue: (data: TData, key: keyof TData) => string;
  variant: IconCardVariant;
  hideZeroValues?: boolean;
}

const mapDataToGridItems = <TData extends { [key: string]: any }>(
  data: TData,
  items: OverviewItem<TData>[],
  getData: (data: TData, key: keyof TData) => string
) => items.map((item) => ({ ...item, value: getData(data, item.key) }));

export const IconCardGrid = <TData extends { [key: string]: any }>({
  data,
  getDataValue: getData,
  items,
  variant,
  hideZeroValues,
}: IconCardGridProps<TData>) => {
  const isMobile = useIsMobile();
  const ContainerType = isMobile ? Container : Grid;
  const filteredItems = hideZeroValues
    ? items.filter(({ key }) => data[key] !== 0)
    : items;
  const dataItems = mapDataToGridItems(data, filteredItems, getData);

  return (
    <ContainerType>
      {dataItems.map(({ title, icon, value, description }) => (
        <Box
          bg="P100"
          borderRadius="sm"
          p={2}
          flex={1}
          key={title}
          mr={{ _: "6/4", md: 0 }}
          minWidth={{ _: 260, md: 220 }}
          mb={isMobile ? 2 : undefined}
        >
          <Flex alignItems="center" mb={2}>
            <Flex
              as="span"
              borderRadius="full"
              bg={variants[variant]}
              width={32}
              height={32}
              variant="center"
              mr={1}
            >
              <Icon icon={icon} color="white" fontSize={4} />
            </Flex>
            <Text fontWeight="extraBold" color="N500">
              {title}
            </Text>
            {description && (
              <>
                <Icon
                  data-tip={description}
                  icon={"info"}
                  ml={1}
                  fontSize={3}
                />
                <ReactTooltip
                  effect="solid"
                  textColor="black"
                  borderColor="black"
                  backgroundColor="lightgray"
                  multiline={true}
                />
              </>
            )}
          </Flex>
          <Text fontWeight="extraBold" fontSize={6} color="N600">
            {value}
          </Text>
        </Box>
      ))}
    </ContainerType>
  );
};
