import React, { FC } from "react";

import { theme } from "flicket-ui";
import { Status } from "~components";
import { SalesChannelGraphOutput } from "~graphql/sdk";
import { Card } from "../";
import { PieChart } from "../charts";

export const SalesChannelChart: FC<{
  data: SalesChannelGraphOutput;
  error: any;
}> = ({ data, error }) => {
  const items = [
    {
      name: "online",
      label: "Online",
      color: theme.colors.P300,
      value: data?.online,
    },
    {
      name: "backOffice",
      label: "Back office",
      color: theme.colors.S300,
      value: data?.backOffice,
    },
    { name: "pos", label: "POS", color: "#D3CC2C", value: data?.pos },
  ];

  return (
    <Card title="Sales channel">
      <Status loading={!error && !data} error={error}>
        <PieChart
          data={items}
          label={`Transaction${data?.transactions === 1 ? "" : "s"}`}
          total={data?.transactions?.toLocaleString()}
        />
      </Status>
    </Card>
  );
};
