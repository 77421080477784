import React, { FC } from "react";

import { SystemProps, theme, Flex } from "flicket-ui";
import { flatten, uniq, camelCase } from "lodash";

import { GraphOutput } from "~graphql/sdk";
import { formatDate, getRandomColor } from "~lib/helpers";

import { BarChart } from "../charts";

export const SalesChart: FC<
  { data: GraphOutput; dateFormat?: string; error: any } & SystemProps
> = ({ data, dateFormat = "d MMM yyyy", error, ...props }) => {
  const options = {
    x: {
      tickFormatter: (val) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        const date = new Date(val);
        date.setHours(0, 0, 0, 0);
        return formatDate(date, dateFormat);
      },
    },
  };

  const COLORS = [
    theme.colors.P300,
    theme.colors.P200,
    theme.colors.P100,
    theme.colors.S200,
  ];

  const formattedBars = uniq(
    flatten(
      data?.dates
        ?.filter((item) => !!item.items?.length)
        .map((item) => item.items)
    ).map((item) => item.name)
  ).map((name, index) => ({
    name,
    dataKey: camelCase(name),
    fill:
      camelCase(name) === "addOn"
        ? theme.colors.S300
        : COLORS[index] || getRandomColor(),
    stackId: camelCase(name) === "addOn" ? "b" : "a",
  }));

  const formattedGraphData = data?.dates?.map(({ date, items }) => {
    const obj = {};

    items.forEach((item) => (obj[camelCase(item.name)] = item.value));

    return { date, ...obj };
  });

  return formattedBars?.length ? (
    <BarChart
      data={formattedGraphData}
      options={options}
      items={formattedBars}
    />
  ) : (
    <Flex p={5} width={1} variant="center">
      No results
    </Flex>
  );
};
