import React, { FC, useState } from "react";
import { SystemProps, theme, Box } from "flicket-ui";
import {
  BarChart as Chart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
} from "recharts";

import { useIsMobile } from "~hooks";
import { formatDate } from "~lib/helpers";

import { Legend } from "./Legend";

export const BarChart: FC<
  {
    data: any;
    items: any;
    options?: any;
    hideLegend?: boolean;
  } & SystemProps
> = ({ data, items, hideLegend, ...props }) => {
  const [inactiveItems, setInactive] = useState([]);
  const isMobile = useIsMobile("lg");

  const options = {
    chart: {
      margin: {
        top: 20,
        right: 0,
        left: 0,
        bottom: !isMobile && data.length > 10 && data.length <= 30 ? 70 : 24,
      },
    },
    x: {
      axisLine: false,
      tickLine: false,
      interval: 0,
      tick: {
        dy: !isMobile && data.length > 10 && data.length <= 30 ? 35 : 8,
        fontSize: 14,
        color: theme.colors.N500,
      },
      angle: !isMobile && data.length > 10 && data.length <= 30 ? -75 : 0,
      ...((data.length > 30 || isMobile) && { interval: "preserveStartEnd" }),
    },
    y: {
      axisLine: false,
      tickLine: false,
      tick: { dx: -16, fontSize: 12, color: theme.colors.N500 },
      padding: { left: 0, top: 0, right: 0, bottom: 0 },
    },
    grid: {
      strokeDasharray: "0",
      vertical: false,
      stroke: theme.colors.N200,
    },
    tooltip: {
      itemStyle: { fontSize: 14, color: theme.colors.N800 },
      contentStyle: { fontSize: 14, color: theme.colors.N800 },
      labelStyle: { fontSize: 14, fontWeight: "bold", marginBottom: 12 },
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      labelFormatter: (val) => formatDate(val, "dd MMM yyyy"),
    },
  };

  const toggleItem = (key) =>
    inactiveItems.includes(key)
      ? setInactive(inactiveItems?.filter((b) => b !== key))
      : setInactive([...inactiveItems, key]);

  return (
    <>
      <Box width="100%" height={isMobile ? 220 : 340}>
        <ResponsiveContainer width="100%" height="100%">
          <Chart data={data} {...options.chart} {...props.options?.bar}>
            <CartesianGrid {...options.grid} {...props.options?.grid} />
            <XAxis dataKey="date" {...options.x} {...props.options?.x} />
            <YAxis {...options.y} {...props.options?.y} allowDecimals={false} />
            <Tooltip {...options.tooltip} {...props.options?.tooltip} />

            {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call*/}
            {items.map((item, index) =>
              inactiveItems.includes(item.dataKey) ? null : (
                <Bar key={index} {...item} />
              )
            )}
          </Chart>
        </ResponsiveContainer>
      </Box>

      {!hideLegend && (
        <Legend
          payload={items}
          onToggle={toggleItem}
          inactiveItems={inactiveItems}
        />
      )}
    </>
  );
};
