import { Box, Flex, formatPrice, Text } from "flicket-ui";
import { Icon, Status } from "~components";
import { ReportingFilterSource } from "~graphql/sdk";
import { Card, Grid } from ".";
import { useQuery } from "~hooks/useQuery";
import { SummaryOverviewDocument } from "~graphql/typed-document-nodes";
import { subDays } from "date-fns";
import { today } from "~features/reports/constants";

export function OverviewSummary() {
  const { data, error, isLoading } = useQuery(SummaryOverviewDocument, {
    channel: null,
    source: ReportingFilterSource.Overall,
    startDate: subDays(today, 365).toISOString(),
    endDate: today.toISOString(),
  });

  const summaryOverview = data?.summaryOverview;

  const overviewItems = [
    {
      title: "Tickets sold today",
      icon: "my-tickets",
      key: "ticketsSoldToday",
    },
    {
      title: "Tickets sold yesterday",
      icon: "my-tickets",
      key: "ticketsSoldYesterday",
    },
    { title: "Total tickets sold", icon: "my-tickets", key: "ticketsPaid" },

    { title: "Total revenue", icon: "trending_up", key: "totalRevenue" },
    { title: "Today's revenue", icon: "trending_up", key: "todayRevenue" },
    {
      title: "Yesterday's revenue",
      icon: "trending_up",
      key: "yesterdayRevenue",
    },
    { title: "Orders", icon: "orders", key: "orders" },
    {
      title: "Total tickets issued",
      icon: "my-tickets",
      key: "ticketsSold",
    },
    { title: "Cart abandonment", icon: "cart", key: "cartAbandonment" },
    {
      title: "Returning customers",
      icon: "customer",
      key: "returningCustomers",
    },
    { title: "Total comps issued", icon: "my-tickets", key: "ticketsComp" },
    { title: "Memberships issued", icon: "membership", key: "membershipsSold" },
    {
      title: "Memberships renewed",
      icon: "membership",
      key: "membershipsRenewed",
    },
    { title: "Add-ons issued", icon: "add-cart", key: "addonsSold" },
  ];

  return (
    <>
      <Card title={"Overall summary"} pr={{ _: 0, md: 4 }} position="relative">
        <Status loading={isLoading} error={error}>
          {summaryOverview?.ticketsSold > 0 ||
          summaryOverview?.membershipsSold > 0 ||
          summaryOverview?.addonsSold > 0 ? (
            <Grid>
              {overviewItems.map(({ title, icon, key }) => {
                if (summaryOverview?.[key] == "0") {
                  return;
                } else {
                  return (
                    <Box
                      bg="P100"
                      borderRadius="sm"
                      p={2}
                      flex={1}
                      key={title}
                      mr={{ _: "6/4", md: 0 }}
                      minWidth={{ _: 260, md: 220 }}
                      mb={2}
                    >
                      <Flex alignItems="center" mb={2}>
                        <Flex
                          as="span"
                          borderRadius="full"
                          bg="P300"
                          width={32}
                          height={32}
                          variant="center"
                          mr={1}
                        >
                          <Icon icon={icon as any} color="white" fontSize={4} />
                        </Flex>
                        <Text fontWeight="extraBold" color="N500">
                          {title}
                        </Text>
                      </Flex>
                      <Text fontWeight="extraBold" fontSize={6} color="N600">
                        {key === "totalRevenue" ||
                        key === "todayRevenue" ||
                        key === "yesterdayRevenue"
                          ? formatPrice(summaryOverview?.[key])
                          : summaryOverview?.[key]?.toLocaleString()}
                      </Text>
                    </Box>
                  );
                }
              })}
            </Grid>
          ) : (
            <Flex height={150} variant="center" width={1}>
              There is no activity to show
            </Flex>
          )}
        </Status>
      </Card>
    </>
  );
}

export default OverviewSummary;
