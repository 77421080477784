import React from "react";
import { SecondaryButton } from "flicket-ui";
import { Status, Grid, Divider } from "~components";
import { Card } from "~components/reports";
import { CustomerLocations } from "../charts/CustomerLocations";
import { FinancialGateways } from "./FinancialGateways";
import { FinancialOverview } from "./FinancialOverview";
import { FinancialsChart } from "./FinancialsChart";
import { formatReportingParams } from "../util";
import { PointReportingFilterSource } from "~graphql/sdk";
import { useQuery } from "~hooks/useQuery";
import {
  CustomerLocationsDocument,
  FinancialGatewaysDocument,
  FinancialGraphDocument,
  FinancialOverviewDocument,
} from "~graphql/typed-document-nodes";
import { ParamsProps } from "~features/useParams/hooks/useParams";

type FinancialsProps = { params: ParamsProps };

export const Financials = ({ params }: FinancialsProps) => {
  const { data: graphDataData, error: errorGraph } = useQuery(
    params.ready && FinancialGraphDocument,
    formatReportingParams(params)
  );

  const graphData = graphDataData?.financialGraph;

  const { data: financialOverviewData, error: errorOverview } = useQuery(
    params.ready && FinancialOverviewDocument,
    formatReportingParams(params)
  );

  const financialOverview = financialOverviewData?.financialOverview;

  const {
    data: customerLocationsData,
    error: errorCustomerLocations,
  } = useQuery(
    params.ready && CustomerLocationsDocument,
    formatReportingParams(params)
  );

  const customerLocations = customerLocationsData?.customerLocations;

  const { data: financialGatewaysData, error: errorGateways } = useQuery(
    params.ready && FinancialGatewaysDocument,
    formatReportingParams(params)
  );

  const financialGateways = financialGatewaysData?.financialGateways;

  return (
    <>
      <FinancialOverview
        data={financialOverview}
        error={errorOverview}
        isPoint={params.source === PointReportingFilterSource.Package}
      />

      {params.source !== PointReportingFilterSource.Package && (
        <Card title="Financials" mb={4}>
          <Status loading={!errorGraph && !graphData} error={errorGraph}>
            <FinancialsChart data={graphData} />
          </Status>

          <Divider my={3} />
          <SecondaryButton
            to="/reports/sales"
            display="block"
            ml={"auto" as any}
          >
            View sales & financials breakdown
          </SecondaryButton>
        </Card>
      )}

      <Grid
        width={1}
        gridTemplateColumns={{
          _: "repeat(auto-fill, minmax(300px, 1fr))",
          sm: "repeat(auto-fill, minmax(400px, 1fr))",
        }}
      >
        <CustomerLocations
          data={customerLocations}
          error={errorCustomerLocations}
          variant="financial"
        />
        <FinancialGateways data={financialGateways} error={errorGateways} />
      </Grid>
    </>
  );
};

export default Financials;
