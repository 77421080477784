import React, { FC } from "react";

import { Box, Flex, formatPrice } from "flicket-ui";
import { orderBy } from "lodash";

import { Status } from "~components";
import { LocationOutput } from "~graphql/sdk";
import { Card } from "..";

type FormattedLocationOutput = Omit<LocationOutput, "count"> & {
  count: number;
};

export const CustomerLocations: FC<{
  data?: LocationOutput[];
  error: any;
  variant: "sales" | "financial";
}> = ({ data, error, variant }) => {
  // Hide the graph if there is no data
  if (data && data.length === 0) return null;

  const formattedData: FormattedLocationOutput[] = data?.map((data) => ({
    ...data,
    count: +data.count,
  }));

  const orderedData =
    variant === "sales"
      ? orderBy(formattedData, ["count"], ["desc"])
      : orderBy(formattedData, ["amount"], ["desc"]);

  const renderItem =
    variant === "sales"
      ? (item: FormattedLocationOutput) => (
          <>{item.count?.toLocaleString()} Orders</>
        )
      : (item: FormattedLocationOutput) => formatPrice(item.amount);

  return (
    <Card title="Customer locations">
      <Status loading={!error && !data} error={error}>
        <Box as="ol" pr={2} maxHeight={230} overflowY="auto" width={1}>
          {orderedData?.map((item, i) => (
            <Flex
              as="li"
              borderRadius="xs"
              bg={i === 0 ? "P100" : "white"}
              px={2}
              py="6/4"
              listStyleType="decimal"
              flex={2}
              key={i}
            >
              <Flex as="span" flex={1} mr={4}>
                {i + 1}. {item.city}
              </Flex>
              <Flex as="span" justifyContent="flex-end">
                {renderItem(item)}
              </Flex>
            </Flex>
          ))}
        </Box>
      </Status>
    </Card>
  );
};

export default CustomerLocations;
